import { Injectable } from '@angular/core';
import { AlertService } from 'ngx-kuv-tools';
import { SesionService } from '../sesion/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private alerts: AlertService,
    private sesionService: SesionService,
  ) { }

  showErrorAlerts(error: any): any {
    // Comprobar si es un error de stock
    if (error.status == 401) {
      if (error.error && error.error.msg) return this.alerts.addAlert(error.error.msg, 'danger');
      else if (error.error && error.error.response) return this.alerts.addAlert(error.error.response, 'danger');
      return;
    }
    if (error.error && error.error.stock) {
      if (error.error && error.error.msg) return this.alerts.addAlert(error.error.msg, 'danger');
      else if (error.error && error.error.response) return this.alerts.addAlert(error.error.response, 'danger');
      return;
    }
    if (error.error) {
      if (error.error && error.error.msg) this.alerts.addAlert(error.error.msg, 'danger');
      else if (error.error && error.error.response) this.alerts.addAlert(error.error.response, 'danger');
      return;
    }
    if (error.response) this.alerts.addAlert(error.response, 'danger');
    if (error.error && error.error.errors) {
      error.error.errors.forEach((element: any) => {
        if (element.msg) this.alerts.addAlert(element.msg, 'danger');
        else if (element.response) this.alerts.addAlert(element.response, 'danger');
      });
    }
    return;
  }

  porCaducar(fechaEntrada: Date): boolean {
    // Fecha Actual.
    const hoy: Date = new Date();
    // Calcula la fecha de caducidad.
    const fechaCaducidad: Date = new Date(fechaEntrada.getTime() + 7 * 24 * 60 * 60 * 1000);
    // Obtiene la fecha una semana antes de la fecha de caducidad.
    const unaSemanaAntesCaducidad: Date = new Date(fechaCaducidad.getTime() - 7 * 24 * 60 * 60 * 1000);
    // Verifica si la fecha de hoy está exactamente una semana antes de la fecha de caducidad.
    const porVencer = hoy.getTime() == unaSemanaAntesCaducidad.getTime();
    return porVencer;
  }

  lastDayOfMonth(date = new Date()): Date {
    let data = this.extract(date);
    return new Date(data.anio, data.mes, 0, data.hours, data.minutes, data.seconds);
  }

  extract(date = new Date()) {
    // date.setHours(date.getHours() + Math.floor(date.getTimezoneOffset() / 60) - 3);
    let dia = date.getDate();
    let dia2 = (dia < 10) ? `0${dia}` : dia.toString();
    let diaSemana = date.getDay();
    let mes = (date.getMonth() - 0) + 1;
    let mes2 = (mes < 10) ? `0${mes}` : mes.toString();
    let anio = date.getFullYear();
    let hours = date.getHours();
    let hours2 = (hours < 10) ? `0${hours}` : hours.toString();
    let minutes = date.getMinutes();
    let minutes2 = (minutes < 10) ? `0${minutes}` : minutes.toString();
    let seconds = date.getSeconds();
    let seconds2 = (seconds < 10) ? `0${seconds}` : seconds.toString();

    return {
      dia,
      dia2,
      diaSemana,
      mes,
      mes2,
      anio,
      hours,
      hours2,
      minutes,
      minutes2,
      seconds,
      seconds2
    };
  }

  /**
   * @param valorBruto
   * @param iva 19,5 21 etc. undefined indica usar iva de la empresa
   * @returns
   */
  valorBrutoANeto(valorBruto: number, iva: number | undefined = undefined) {
    if (isNaN(valorBruto)) return 0;
    if (iva !== undefined && isNaN(iva)) {
      return 0;
    }

    const valorIva = iva === undefined ? this.sesionService.IVA : iva;
    const factor = 1 + this.ivaEnDecimal(valorIva);

    const neto = valorBruto / factor;

    return this.redondearDecimalesSegunPais(neto);
  }

  /**
   * @param valorNeto
   * @param iva 19.5 21 undefined indica usar iva de la empresa
   * @returns
   */
  valorNetoABruto(valorNeto: number, iva: number | undefined = undefined) {
    if(isNaN(valorNeto)) return 0;
    if (iva !== undefined && isNaN(iva)) {
      return 0;
    }

    const valorIva = iva === undefined ? this.sesionService.IVA : iva;
    const factor = 1 + this.ivaEnDecimal(valorIva);

    const bruto = valorNeto * factor;

    return this.redondearDecimalesSegunPais(bruto);
  }

  ivaEnDecimal(iva: number) {
    return iva / 100;
  }

  // test 1.3549999999999998
  // https://stackoverflow.com/a/18358056/6237795
  redondearDecimales(num: number, decimales = 0) {
    if (decimales == 0) {
      return Math.round(num);
    } else if (decimales == 2) {
      // TODO
      // @ts-ignore
      return +(Math.round(num + "e+2")  + "e-2");
    }
    // TODO revisar
    return +num.toFixed(decimales);
  }

  redondearDecimalesSegunPais(num: number) {
    if (this.sesionService.esPaisChile()) {
      return this.redondearDecimales(num, 0);
    }
    return this.redondearDecimales(num, 2);
  }

  decimalesIguales(num1: number, num2: number, delta = 0.0001): boolean {
    return Math.abs(num1 - num2) < delta;
  }
}
