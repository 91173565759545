import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-test-qr',
  templateUrl: './test-qr.component.html',
  styleUrls: ['./test-qr.component.scss']
})
export class TestQrComponent implements OnInit {
  qrResult: string | null = null;
  device: any; // Puedes asignar el dispositivo de la cámara si tienes uno específico

  constructor() { }

  ngOnInit() { }

  // Función que se ejecuta cuando el QR es escaneado
  handleQrCodeResult(event: string): void {
    this.qrResult = event;
  }
}
