import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './utils/login/login.component';
import { AuthGuard } from './utils/auth/auth.guard';
import { TosComponent } from './utils/tos/tos/tos.component';
import { NuevaContrasenaComponent } from './utils/nueva-contrasena/nueva-contrasena.component';
import { RecuperarContrasenaComponent } from './utils/recuperar-contrasena/recuperar-contrasena.component';
import { SoporteComponent } from './utils/soporte/soporte.component';
import { LoadingComponent } from 'ngx-kuv-tools';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { TestQrComponent } from './components/qr/test-qr/test-qr.component';
import { SuscripcionesTerminosComponent } from './components/suscripciones/suscripciones-terminos/suscripciones-terminos.component';
import { SubscriptionTosComponent } from './utils/subscription-tos/subscription-tos.component';
import { AyudaComponent } from './utils/ayuda/ayuda.component';

const routes: Routes = [
  { path: 'test-qr', component: TestQrComponent },
  { path: 'reportes', loadChildren: () => import('./components/reportes/reportes.module').then(m => m.ReportesModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'clientes', loadChildren: () => import('./components/cliente/cliente.module').then(m => m.ClienteModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'credito', loadChildren: () => import('./components/credito/credito.module').then(m => m.CreditoModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'revisiones', loadChildren: () => import('./components/revision/revision.module').then(m => m.RevisionModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 5, 6, 7] } },
  { path: 'producto', loadChildren: () => import('./components/producto/producto.module').then(m => m.ProductoModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'actualizacion-stock', loadChildren: () => import('./components/actualizacion-stock/actualizacion-stock.module').then(m => m.ActualizacionStockModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 7] } },

	{ path: 'publicidad-partner', loadChildren: () => import('./components/publicidad-partner/publicidad-partner.module').then(m => m.PublicidadPartnerModule) },
	{ path: 'categoria-partner', loadChildren: () => import('./components/categoria-partner/categoria-partner.module').then(m => m.CategoriaPartnerModule) },
	{ path: 'partner', loadChildren: () => import('./components/partner/partner.module').then(m => m.PartnerModule) },
	{ path: 'planes', loadChildren: () => import('./components/suscripciones/suscripciones.module').then(m => m.SuscripcionesModule) },
  { path: 'credito', loadChildren: () => import('./components/credito/credito.module').then(m => m.CreditoModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'reportes', loadChildren: () => import('./components/reportes/reportes.module').then(m => m.ReportesModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'login', component: LoginComponent },
  { path: 'loading', component: LoadingComponent },
  { path: 'soporte', component: SoporteComponent },
  { path: 'ayuda', component: AyudaComponent },
  { path: 'terminos', component: TosComponent },
  { path: 'recuperar-password', component: RecuperarContrasenaComponent },
  { path: 'restablecer/:token', component: NuevaContrasenaComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'suscripcion-tos', component: SubscriptionTosComponent },
  // Rutas Protegidas
  { path: 'clientes', loadChildren: () => import('./components/cliente/cliente.module').then(m => m.ClienteModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'reuniones', loadChildren: () => import('./components/reunion/reunion.module').then(m => m.ReunionModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'motivos-reunion', loadChildren: () => import('./components/motivo-reunion/motivo-reunion.module').then(m => m.MotivoReunionModule) },
  { path: 'usuarios', loadChildren: () => import('./components/usuario/usuario.module').then(m => m.UsuarioModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'vehiculos', loadChildren: () => import('./components/vehiculo/vehiculo.module').then(m => m.VehiculoModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'recepcion-vehiculos', loadChildren: () => import('./components/recepcion-vehiculo/recepcion-vehiculo.module').then(m => m.RecepcionVehiculoModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'item-checklist', loadChildren: () => import('./components/item-checklist/item-checklist.module').then(m => m.ItemChecklistModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'revisiones', loadChildren: () => import('./components/revision/revision.module').then(m => m.RevisionModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 5, 6, 7] } },
  { path: 'publicidades', loadChildren: () => import('./components/publicidad/publicidad.module').then(m => m.PublicidadModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'tipo-servicio', loadChildren: () => import('./components/tipo-revision/tipo-revision.module').then(m => m.TipoRevisionModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'tipo-actualizacion', loadChildren: () => import('./components/tipo-actualizacion/tipo-actualizacion.module').then(m => m.TipoActualizacionModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 7] } },
  { path: 'tipo-gastos', loadChildren: () => import('./components/tipo-gastos/tipo-gastos.module').then(m => m.TipoGastosModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'gastos', loadChildren: () => import('./components/gastos/gastos.module').then(m => m.GastosModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'proveedor', loadChildren: () => import('./components/proveedor/proveedor.module').then(m => m.ProveedorModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'compra', loadChildren: () => import('./components/compra/compra.module').then(m => m.CompraModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'empresa', loadChildren: () => import('./components/empresa/empresa.module').then(m => m.EmpresaModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 100] } },
  { path: 'categoria', loadChildren: () => import('./components/categoria/categoria.module').then(m => m.CategoriaModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'servicio', loadChildren: () => import('./components/servicio/servicio.module').then(m => m.ServicioModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3, 6, 7] } },
  { path: 'comision-encargado', loadChildren: () => import('./components/comision-encargado/comision-encargado.module').then(m => m.ComisionEncargadoModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'encuesta-nps', loadChildren: () => import('./components/encuesta-nps/encuesta-nps.module').then(m => m.EncuestaNpsModule), canActivate: [AuthGuard], data: { expectedRoles: [0, 1] } },
  // SII
  { path: 'sii', loadChildren: () => import('./components/sii/sii.module').then(m => m.SiiModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  // Default
  { path: '', redirectTo: 'revisiones/index', pathMatch: 'full' },
  { path: 'home', redirectTo: 'reportes/ventas', pathMatch: 'full' },
  { path: '**', redirectTo: 'page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
