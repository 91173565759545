import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SuscripcionesService } from '../suscripciones.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormatterService } from 'src/app/utils/formatter/formatter.service';
import { DateService } from 'src/app/utils/date/date.service';
import { SuscripcionesTerminosComponent } from '../suscripciones-terminos/suscripciones-terminos.component';
import { SesionService } from 'src/app/utils/sesion/sesion.service';
import { environment } from 'src/environments/environment';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { SentryService } from 'src/app/utils/sentry/sentry.service';
import { ModalContactoSoporteComponent } from 'src/app/shared/contacto-soporte/contacto-soporte.component';
import { HelperService } from 'src/app/utils/helper/helper.service';
import { SuscripcionesEnlaceGeneradoComponent } from '../suscripciones-enlace-generado/suscripciones-enlace-generado.component';

@Component({
  selector: 'app-suscripciones-informacion-planes',
  templateUrl: './suscripciones-informacion-planes.component.html',
  styleUrls: ['./suscripciones-informacion-planes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuscripcionesInformacionPlanesComponent implements OnInit {

  @Input() empresa_id: number | null = null;
  @Input() plan: string = '';
  @Input() periodo: string = 'MENSUAL';
  @Input() montoParcialSeleccionado: number = 0;
  @Input() suscripcionPrevia: any;
  @Input() suscripcionVPPrevia: any;
  @Input() suscripcionDLGPrevia: any;

  @Input() generarEnlace: boolean = false; // Indica si el Paywall entra en modo de generar enlace.
  @Input() paisID: number = 0; // Fuerza al Paywall a usar el ID asignado, ignorando el de la empresa.
  @Input() email: string = ''; // Email de la empresa. Utilizado en la generación de enlace.

  montoParcialDiarioFiltroX: number = 0;
  montoParcialMensualFiltroX: number = 0;
  montoParcialAnualFiltroX: number = 0;

  montoParcialDiarioAcelera: number = 0;
  montoParcialMensualAcelera: number = 0;
  montoParcialAnualAcelera: number = 0;

  montoParcialDiarioTurbo: number = 0;
  montoParcialMensualTurbo: number = 0;
  montoParcialAnualTurbo: number = 0;

  montosFiltroX = [350, 350, 500, 350]; //[*, * , * , 167]
  montosAcelera = [350, 350, 833, 694];
  montosTurbo = [350, 350, 1167, 972];
  dias: number = 0;
  fecha: Date = new Date();

  get montoFiltroXDiario(): number { return 1500; };
  get montoFiltroXMensual(): number { return 15000; };
  get montoFiltroXAnual(): number { return 150000; };

  get montoFiltroXDiarioUSD(): number { return 2.00; };
  get montoFiltroXMensualUSD(): number { return 20.00; };
  get montoFiltroXAnualUSD(): number { return 200.00; };

  get montoAceleraDiario(): number { return 2500; };
  get montoAceleraMensual(): number { return 25000; };
  get montoAceleraAnual(): number { return 250000; };

  get montoAceleraDiarioUSD(): number { return 3.00; };
  get montoAceleraMensualUSD(): number { return 30.00; };
  get montoAceleraAnualUSD(): number { return 300.00; };

  get montoTurboDiario(): number { return 3500; };
  get montoTurboMensual(): number { return 35000; };
  get montoTurboAnual(): number { return 350000; };

  get montoTurboDiarioUSD(): number { return 4.00; };
  get montoTurboMensualUSD(): number { return 40.00; };
  get montoTurboAnualUSD(): number { return 400.00; };

  get montoPlanPrevio(): number {
    if (this.suscripcionVPPrevia) return Math.round(this.suscripcionVPPrevia.amount / 1.19);
    return 0;
  }

  get montoFiltroX(): number {
    if (!this.esPaisChile) {
      switch (this.periodo) {
        case 'DIARIO': return this.montoFiltroXDiarioUSD;
        case 'MENSUAL': return this.montoFiltroXMensualUSD;
        case 'ANUAL': return this.montoFiltroXAnualUSD;
        default: return this.montoFiltroXMensualUSD;
      }
    }

    switch (this.periodo) {
      case 'DIARIO': return this.montoFiltroXDiario;
      case 'MENSUAL': return this.montoFiltroXMensual;
      case 'ANUAL': return this.montoFiltroXAnual;
      default: return this.montoFiltroXMensual;
    }
  }

  get montoFiltroXAlt(): number {
    if (!this.esPaisChile) return 0;
    if (!this.planPrevio || this.montoPlanPrevio > this.montoFiltroX) return 0;
    return this.calcularProporcional(this.montoFiltroX - this.montoPlanPrevio);
  }

  get montoAcelera(): number {
    if (!this.esPaisChile) {
      switch (this.periodo) {
        case 'DIARIO': return this.montoAceleraDiarioUSD;
        case 'MENSUAL': return this.montoAceleraMensualUSD;
        case 'ANUAL': return this.montoAceleraAnualUSD;
        default: return this.montoAceleraMensualUSD;
      }
    }

    switch (this.periodo) {
      case 'DIARIO': return this.montoAceleraDiario;
      case 'MENSUAL': return this.montoAceleraMensual;
      case 'ANUAL': return this.montoAceleraAnual;
      default: return this.montoAceleraMensual;
    }
  }

  get montoAceleraAlt(): number {
    if (!this.esPaisChile) return 0;
    if (!this.planPrevio || this.montoPlanPrevio > this.montoAcelera) return 0;
    return this.calcularProporcional(this.montoAcelera - this.montoPlanPrevio);
  }

  get montoTurbo(): number {
    if (!this.esPaisChile) {
      switch (this.periodo) {
        case 'DIARIO': return this.montoTurboDiarioUSD;
        case 'MENSUAL': return this.montoTurboMensualUSD;
        case 'ANUAL': return this.montoTurboAnualUSD;
        default: return this.montoTurboMensualUSD;
      }
    }

    switch (this.periodo) {
      case 'DIARIO': return this.montoTurboDiario;
      case 'MENSUAL': return this.montoTurboMensual;
      case 'ANUAL': return this.montoTurboAnual;
      default: return this.montoTurboMensual;
    }
  }

  get montoTurboAlt(): number {
    if (!this.esPaisChile) return 0;
    if (!this.planPrevio || this.montoPlanPrevio > this.montoTurbo) return 0;
    return this.calcularProporcional(this.montoTurbo - this.montoPlanPrevio);
  }

  get montoParcialFiltroX(): number {
    if (!this.esPaisChile) return 0;
    switch (this.periodo) {
      case 'DIARIO': return this.montoParcialDiarioFiltroX;
      case 'MENSUAL': return this.montoParcialMensualFiltroX;
      case 'ANUAL': return this.montoParcialAnualFiltroX;
      default: return this.montoParcialMensualFiltroX;
    }
  }

  get montoParcialAcelera(): number {
    if (!this.esPaisChile) return 0;
    switch (this.periodo) {
      case 'DIARIO': return this.montoParcialDiarioAcelera;
      case 'MENSUAL': return this.montoParcialMensualAcelera;
      case 'ANUAL': return this.montoParcialAnualAcelera;
      default: return this.montoParcialMensualAcelera;
    }
  }

  get montoParcialTurbo(): number {
    if (!this.esPaisChile) return 0;
    switch (this.periodo) {
      case 'DIARIO': return this.montoParcialDiarioTurbo;
      case 'MENSUAL': return this.montoParcialMensualTurbo;
      case 'ANUAL': return this.montoParcialAnualTurbo;
      default: return this.montoParcialMensualTurbo;
    }
  }

  get montoAhorroFiltroXYear(): number {
    if (!this.esPaisChile) return 40.0;
    return 30000;
  }

  get montoAhorroAceleraYear(): number {
    if (!this.esPaisChile) return 60.0;
    return 50000;
  }

  get montoAhorroTurboYear(): number {
    if (!this.esPaisChile) return 80.0;
    return 70000;
  }

  get planFormateado(): string {
    switch (this.plan) {
      case 'FILTRO_X': return 'Filtro X';
      case 'ACELERA': return 'Acelera+';
      case 'TURBO': return 'Turbo';
      default: return 'Filtro X';
    }
  }

  get intervaloPlanFormateado(): string {
    switch (this.periodo) {
      case 'DIARIO': return 'Diario';
      case 'MENSUAL': return 'Mensual';
      case 'ANUAL': return 'Anual';
      default: return 'Mensual';
    }
  }

  get textoSubmitButton(): string {
    if (this.generarEnlace) {
      if (this.plan && this.planPrevio) return `Cambiar a Plan ${this.planFormateado}  ${this.intervaloPlanFormateado}`;
      if (this.plan) return `Generar Enlace para Plan ${this.planFormateado}  ${this.intervaloPlanFormateado}`;
      return 'Seleccione un plan y duración';
    }

    if (this.plan && this.planPrevio) return `Cambiar a Plan ${this.planFormateado}  ${this.intervaloPlanFormateado}`;
    if (this.plan) return `Continuar con Plan ${this.planFormateado}  ${this.intervaloPlanFormateado}`;
    return 'Seleccione un plan y duración';
  }

  get periodoToInt(): number {
    return this.periodo == 'DIARIO' ? 1 : this.periodo == 'MENSUAL' ? 3 : this.periodo == 'ANUAL' ? 4 : 3;
  }

  get planPrevio(): boolean {
    if (!this.esPaisChile) {
      return this.suscripcionPrevia && this.suscripcionDLGPrevia && !this.sesionService.user.cuenta_prueba && environment.habilitarSuscripciones;
    }
    return this.suscripcionPrevia && this.suscripcionVPPrevia && !this.sesionService.user.cuenta_prueba && environment.habilitarSuscripciones;
  }

  get planPrevioSeleccionado(): string {
    const periodoToInt = this.periodoToInt;
    if (!this.planPrevio || this.suscripcionPrevia.tipo != periodoToInt) return '';
    switch (this.suscripcionPrevia.tipo) {
      case 1: {
        switch (this.suscripcionPrevia.plan_id) {
          case 1: return 'FILTRO_X';
          case 3: return 'ACELERA';
          case 5: return 'TURBO';
          default: return '';
        }
      }
      case 3: {
        switch (this.suscripcionPrevia.plan_id) {
          case 1: return 'FILTRO_X';
          case 3: return 'ACELERA';
          case 5: return 'TURBO';
          default: return '';
        }
      }
      case 4: {
        switch (this.suscripcionPrevia.plan_id) {
          case 2: return 'FILTRO_X';
          case 4: return 'ACELERA';
          case 6: return 'TURBO';
          default: return '';
        }
      }
      default: return '';
    }
  }

  setOfDowngrades(planID: number): number[] {
    switch (planID) {
      case 1: return [1];
      case 2: return [1, 3, 4];
      case 3: return [1];
      case 4: return [1, 2, 3, 5];
      case 5: return [1, 3, 5];
      case 6: return [1, 2, 3, 4, 5];
      default: return [];
    }
  }

  get isDowngradeFiltroX(): boolean {
    if (!this.suscripcionPrevia || !this.suscripcionVPPrevia) return false;
    const planesDowngrade = this.setOfDowngrades(this.suscripcionPrevia.plan_id);
    return planesDowngrade.indexOf(this.periodo == 'ANUAL' ? 2 : 1) != -1;
  }

  get isDowngradeAcelera(): boolean {
    if (!this.suscripcionPrevia || !this.suscripcionVPPrevia) return false;
    const planesDowngrade = this.setOfDowngrades(this.suscripcionPrevia.plan_id);
    return planesDowngrade.indexOf(this.periodo == 'ANUAL' ? 4 : 3) != -1;
  }

  get isDowngradeTurbo(): boolean {
    if (!this.suscripcionPrevia || !this.suscripcionVPPrevia) return false;
    const planesDowngrade = this.setOfDowngrades(this.suscripcionPrevia.plan_id);
    return planesDowngrade.indexOf(this.periodo == 'ANUAL' ? 6 : 5) != -1;
  }

  get planPrevioEsMensual(): boolean {
    return this.suscripcionPrevia && this.suscripcionPrevia.tipo == 3;
  }

  get planPrevioEsAnual(): boolean {
    return this.suscripcionPrevia && this.suscripcionPrevia.tipo == 4;
  }

  get esPaisChile(): boolean {
    return this.sesionService.esPaisChile(this.paisID ? this.paisID : this.sesionService.usuario.pais_id);
  }

  get currency(): string {
    return this.esPaisChile ? '$' : 'USD $';
  }

  formatPrice(num: number): string {
    if (this.esPaisChile) return this.formatter.numberFormat(num);
    return this.formatter.numberFormat(num, 2, 2, '', 'en-US');
  }

  constructor(
    public activeModal: NgbActiveModal,
    public service: SuscripcionesService,
    public formatter: FormatterService,
    public date: DateService,
    private modalService: NgbModal,
    private sesionService: SesionService,
    private loading: LoadingService,
    private alerts: AlertService,
    private sentryService: SentryService,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    this.fecha = this.date.addTime('+1 mes', this.date.firstDayOfMonth());
    let dia = this.date.extract(new Date()).dia;
    this.dias = 30 - ((dia == 31 ? 30 : dia) - 1);

    this.montoParcialDiarioFiltroX = 0;
    this.montoParcialMensualFiltroX = this.montosFiltroX[2] * this.dias;
    this.montoParcialAnualFiltroX = this.suscripcionVPPrevia || this.suscripcionDLGPrevia ? (this.montosFiltroX[3] * this.dias) : 0;

    this.montoParcialDiarioAcelera = 0;
    this.montoParcialMensualAcelera = this.montosAcelera[2] * this.dias;
    this.montoParcialAnualAcelera = this.suscripcionVPPrevia || this.suscripcionDLGPrevia ? (this.montosAcelera[3] * this.dias) : 0;

    this.montoParcialDiarioTurbo = 0;
    this.montoParcialMensualTurbo = this.montosTurbo[2] * this.dias;
    this.montoParcialAnualTurbo = this.suscripcionVPPrevia || this.suscripcionDLGPrevia ? (this.montosTurbo[3] * this.dias) : 0;
  }

  seleccionarPeriodo(tipo: string): void {
    this.periodo = tipo;
    if (this.periodo == 'MENSUAL') {
      if (this.plan == 'FILTRO_X' && (this.isDowngradeFiltroX || this.planPrevioSeleccionado == 'FILTRO_X')) this.plan = '';
      else if (this.plan == 'ACELERA' && (this.isDowngradeAcelera || this.planPrevioSeleccionado == 'ACELERA')) this.plan = '';
      else if (this.plan == 'TURBO' && (this.isDowngradeTurbo || this.planPrevioSeleccionado == 'TURBO')) this.plan = '';
    }
  }

  seleccionarPlan(plan: string): void {
    if (plan == this.planPrevioSeleccionado) return;
    if (!this.esPaisChile && this.planPrevio) return;
    if (plan == 'FILTRO_X') {
      if (this.isDowngradeFiltroX) return;
      this.montoParcialSeleccionado = this.montoParcialFiltroX;
    } else if (plan == 'ACELERA') {
      if (this.isDowngradeAcelera) return;
      this.montoParcialSeleccionado = this.montoParcialAcelera;
    } else if (plan == 'TURBO') {
      if (this.isDowngradeTurbo) return;
      this.montoParcialSeleccionado = this.montoParcialTurbo;
    }
    this.plan = plan;
  }

  continueButton(): void {
    if (!this.periodo) return;
    if (this.generarEnlace) {
      const modalRef = this.modalService.open(SuscripcionesEnlaceGeneradoComponent, { size: 'lg', scrollable: true, centered: true, backdrop: 'static' });
      const dataForURL = {
        email: this.email,
        empresaID: this.empresa_id,
        paisID: this.paisID,
        tipo: this.periodo,
        plan: this.plan,
        montoParcial: this.montoParcialSeleccionado,
      }
      const paramsAsString = JSON.stringify(dataForURL);
      const baseUrl = `${environment.webURL}suscripcion-tos?payload=${btoa(paramsAsString)}`;
      modalRef.componentInstance.url = baseUrl;
      modalRef.componentInstance.email = this.email;
      modalRef.componentInstance.intervalo = this.periodo;
      modalRef.componentInstance.plan = this.plan;
      modalRef.componentInstance.empresaID = this.empresa_id;
      modalRef.result.then((result: any) => {
        this.activeModal.close();
      }, (reason: any) => {
        this.activeModal.close();
      });
      return;
    }

    const modalRef = this.modalService.open(SuscripcionesTerminosComponent, { size: 'xl', scrollable: true, centered: true, backdrop: 'static' });
    modalRef.componentInstance.empresa_id = this.empresa_id;
    modalRef.componentInstance.tipo = this.periodo;
    modalRef.componentInstance.plan = this.plan;
    modalRef.componentInstance.montoParcial = this.montoParcialSeleccionado;
    modalRef.result.then((result: any) => {
      this.activeModal.close();
    }, (reason: any) => {
      this.activeModal.close();
    });
  }

  helpButton(): void {
    const modalRef = this.modalService.open(ModalContactoSoporteComponent, { windowClass: 'clear-modal', size: 's', scrollable: true, centered: true });
    modalRef.componentInstance.soloDemo = this.sesionService.user.cuenta_prueba;
    modalRef.componentInstance.showTutorialButton = false;
    modalRef.result.then((result: any) => {
    }, (reason: any) => { });
  }

  loadSuscripcionActual(): void {
    this.loading.show();
    this.service.planActual().subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.suscripcionPrevia = res.suscripcion;
      },
      error: (err: any) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert("Error al obtener la información.", "danger");
        this.sentryService.captureException(err.error);
      }
    });
  }

  /**
  * Obtiene el monto parcial basado en los días restantes.
  * @param {*} monto Monto total de producto.
  * @returns Monto parcial calculado.
  */
  calcularProporcional(monto: number) {
    let now = new Date();
    let lastDay = this.helperService.lastDayOfMonth();
    let dias = now.getDate();
    let diasFinMes = lastDay.getDate();
    return Math.round((monto / 30) * (diasFinMes - (dias - 1)));
  }
}
