<div class="modal-header">
    <h4 class="modal-title">Enlace de Suscripción Generado</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12 col-sm-11">
                <label for="url">Enlace de Pago</label>
                <input class="form-control" type="text" formControlName="url" [readOnly]="true"
                    [ngClass]="{'is-invalid': form.controls['url'].errors && form.controls['url'].touched}">
                <div class="invalid-feedback">
                    Debe ingresar un valor en el campo.
                </div>
            </div>
            <div class="col-12 col-sm-1">
                <button type="button" class="btn btn-success mt-4" (click)="copyToClipboard()"><i class="fas fa-copy"></i></button>
            </div>
        </div>
        <br>
        <hr class="my-3">
        <br>
        <div class="row">
            <div class="col-12 col-sm-10">
                <label for="email">Enviar al Correo Electrónico</label>
                <input class="form-control" type="text" formControlName="email"
                    [ngClass]="{'is-invalid': form.controls['email'].errors && form.controls['email'].touched}">
                <div class="invalid-feedback">
                    Correo electrónico en formato no válido.
                </div>
            </div>
            <div class="col-12 col-sm-2">
                <button type="button" class="btn btn-success mt-4 w-100" (click)="sendEmail()"><i class="fas fa-envelope me-2"></i>Enviar</button>
            </div>
        </div>
        <br>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="openLink()"><i class="fas fa-eye me-2"></i>Abrir enlace</button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Cancel click')">Cerrar</button>
</div>