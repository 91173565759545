import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import copy from 'copy-to-clipboard';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { SuscripcionesService } from '../suscripciones.service';
import { SentryService } from 'src/app/utils/sentry/sentry.service';

@Component({
  selector: 'app-suscripciones-enlace-generado',
  templateUrl: './suscripciones-enlace-generado.component.html',
  styleUrls: ['./suscripciones-enlace-generado.component.scss']
})
export class SuscripcionesEnlaceGeneradoComponent implements OnInit {
  @Input() url: string = '';
  @Input() email: string = '';
  @Input() empresaID: string = '';
  @Input() intervalo: string = '';
  @Input() plan: string = '';
  form: FormGroup = new FormGroup({});

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private alerts: AlertService,
    private loading: LoadingService,
    private suscripcionService: SuscripcionesService,
    private sentryService: SentryService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      url: [this.url, [Validators.required]],
      email: [this.email, [Validators.required, Validators.email]],
    });
    this.copyToClipboard();
  }

  copyToClipboard(): void {
    const textToCopy = this.form.get('url')?.value;
    if (textToCopy) {
      copy(textToCopy);
      this.alerts.addAlert("Enlace copiado al portapapeles.", "info");
    }
  }

  sendEmail(): void {
    if(!this.form.valid) {
      this.alerts.addAlert("Ingrese un correo electrónico válido.", "warning");
      return;
    }
    this.loading.show();
    const data = {
      email: this.form.value.email,
      url: this.url,
      empresa_id: this.empresaID,
      intervalo: this.intervalo,
      plan: this.plan,
    };
    this.suscripcionService.enviarEnlacePagoEnlaceExternoEnEmail(data).subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.alerts.addAlert("Correo electrónico enviado correctamente.", "info");
      },
      error: (err: any) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert("Error al obtener el enlace de Pago.", "danger");
        this.sentryService.captureException(err.error);
      }
    });
  }

  openLink(): void {
    window.open(this.form.get('url')?.value, "_blank");
  }
}
