<div class="modal-header">
    <h6 class="modal-title">Por favor, lea atentamente los Términos y Condiciones</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <markdown [data]="md"></markdown>
</div>
<div class="modal-footer">
    <div class="d-flex justify-content-center w-100 gap-3">
        <button type="button" class="btn btn-outline-danger w-50"
            (click)="activeModal.dismiss('Cross click')">Rechazar</button>
        <button type="button" class="btn btn-primary w-50" (click)="aceptar()">Aceptar</button>
    </div>
</div>