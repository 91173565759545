import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SesionService } from '../sesion/sesion.service';

interface YoutubeLink {
  id?: string;
  url: string;
  titulo: string;
  image?: string;
  duracion?: number;
}

interface ModuloLink {
  url?: string;
  route: string;
  titulo: string;
  icon: string;
  descripcion: string;
}

const linksYoutube: YoutubeLink[] = [
  { url: 'https://www.youtube.com/watch?v=OYkRoa45QqY', titulo: '1. Módulo Reportes Lubrikuv', duracion: 9 },
  { url: 'https://www.youtube.com/watch?v=L_oPCBut-Fc', titulo: '2. Módulo Clientes Lubrikuv', duracion: 10 },
  { url: 'https://www.youtube.com/watch?v=F65FPHJIk3Y', titulo: '3. Módulo Taller Lubrikuv', duracion: 16 },
  { url: 'https://www.youtube.com/watch?v=ippt4bWlbyA', titulo: '4. Módulo Ventas Lubrikuv', duracion: 3 },
  { url: 'https://www.youtube.com/watch?v=U-Nf5k7FGJ8', titulo: '5. Módulo Cotizaciones Lubrikuv', duracion: 5 },
  { url: 'https://www.youtube.com/watch?v=5JybkdV6kUU', titulo: '6. Módulo Inventario Lubrikuv', duracion: 16 },
  { url: 'https://www.youtube.com/watch?v=XsJB5v48Nn4', titulo: '7. Módulo Egresos Lubrikuv', duracion: 9 },
  { url: 'https://www.youtube.com/watch?v=6snljlqUdk4', titulo: '8. Módulo Usuarios Lubrikuv', duracion: 5 },
  { url: 'https://www.youtube.com/watch?v=nhfaiRJM-U8', titulo: '9. Módulo Publicidad Lubrikuv', duracion: 9 },
  { url: 'https://www.youtube.com/watch?v=xIguGgV03C0', titulo: '10. Módulo Empresa Lubrikuv', duracion: 4 },
];
const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis turpis arcu, et finibus lorem efficitur at. Praesent dictum mollis placerat. Nulla id lobortis velit. Vivamus tempus et lacus eget malesuada. In volutpat mauris sed sagittis matti';

const linksModulos: ModuloLink[] = [
  { route: '/revisiones/index', titulo: 'Órdenes de Trabajo', icon: 'oil-can', descripcion: 'Organiza y gestiona tus órdenes de trabajo detalladas sincronizadas a tu inventario, con historial del vehículo y cliente, asegura que nada se te escape. ¡Optimiza tu tiempo y mejora la experiencia de tus clientes!' },
  { route: '/revisiones/index-venta', titulo: 'Ventas', icon: 'money-bill', descripcion: 'Realiza ventas rápidas y eficaces solo de productos sincronizado a tu inventario, hazlo simple y en segundos. Registra cada venta al instante y ofrece una experiencia profesional a tus clientes. Este módulo es solo para ventas de productos.' },
  { route: '/publicidades/index', titulo: 'Publicidad', icon: 'bullhorn', descripcion: 'Diseña promociones exclusivas, personalizadas y comunícalas a tus clientes en segundos. Mantén empresa automotriz siempre en la mente de tus clientes y aumenta tus ventas sin esfuerzo. ¡Despierta tú creatividad!' },
  { route: '/reuniones/calendario', titulo: 'Citas', icon: 'calendar-day', descripcion: 'Llena tu agenda sin estrés. Permite que tus clientes agenden servicios directamente desde la App Lubrikuv y organiza las citas de tu taller. Tú también puedes registrar citas sin perder oportunidades. ¡Tus clientes felices, y tú, organizado!' },
  { route: '/compra/index', titulo: 'Compras', icon: 'money-check-alt', descripcion: 'Gestiona las compras de tu empresa, asociando a tus proveedores para aumentar el stock de tus productos.  Registra los pagos para que tengas todo bajo control y organizado. ¡No pierdas más dinero por desorganización!' },
  { route: '/credito/index', titulo: 'Cobranza', icon: 'hand-holding-usd', descripcion: 'Gestiona tus cuentas por cobrar de forma sencilla. Monitorea clientes con crédito, y asegura el flujo de efectivo de tu empresa automotriz. ¡Di adiós a los olvidos y las pérdidas!' },
]

@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.scss']
})
export class AyudaComponent implements OnInit {
  linksYoutube: YoutubeLink[] = linksYoutube;
  linksCarousel: (YoutubeLink[])[] = [];
  linksModulos = linksModulos;

  constructor(
    private sesion: SesionService,
  ) { }

  ngOnInit(): void {
    this.linksYoutube = this.linksYoutube.map(link => this.procesarLinkYoutube(link));
    this.slidesCarousel(this.linksYoutube);
    this.linksModulos = this.linksModulos.map(link => this.procesarLinkModulo(link));
  }

  procesarLinkModulo(link: ModuloLink) {
    return {
      ...link,
      //url: this.sesion.
    }
  }

  slidesCarousel(links: YoutubeLink[]) {
    let count = 0;
    let tmp = [];
    for (const link of links) {
      tmp.push(link);
      count++;
      if (count === 3) {
        this.linksCarousel.push([...tmp]);
        tmp = [];
        count = 0;
      }
    }
    if (tmp.length > 0) {
      this.linksCarousel.push([...tmp]);
    }
  }

  procesarLinkYoutube(link: YoutubeLink): YoutubeLink {
    const id = this.getIdLink(link.url);
    const image = this.getThumbnailLink(id);

    return {
      ...link,
      id,
      image,
    }
  }

  getIdLink(url: string) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);

    return (match && match[7].length == 11) ? match[7] : '';
  }

  getThumbnailLink(id: string) {
    return `https://img.youtube.com/vi/${id}/0.jpg`;
  }
}