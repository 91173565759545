import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { SesionService } from 'src/app/utils/sesion/sesion.service';
import { FormatterService } from '../formatter/formatter.service';
import { SucursalService } from 'src/app/components/sucursal/sucursal.service';
import { SuscripcionesPagoPendienteComponent } from 'src/app/components/suscripciones/suscripciones-pago-pendiente/suscripciones-pago-pendiente.component';
import { VersionUpdateComponent } from '../version-update/version-update.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownService } from 'ngx-markdown';
import { UsuarioPassChangeComponent } from 'src/app/components/usuario/usuario-pass-change/usuario-pass-change.component';
import data from './../sesion/routes.json'
import { environment } from 'src/environments/environment';
import { SentryService } from '../sentry/sentry.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('.5s ease-out', style({ transform: 'translateX(0%)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('.5s ease-in',
          style({ transform: 'translateX(-100%)', opacity: 0 }))
      ])
    ]),
    trigger('crop', [
      transition(':enter', [
        style({ transform: 'scale(120%)', opacity: 0 }),
        animate('.5s ease-out', style({ transform: 'scale(100%)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(100%)', opacity: 1 }),
        animate('.5s ease-in',
          style({ transform: 'scale(120%)', opacity: 0 }))
      ])
    ]),
    trigger('slideup', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('.5s ease-out', style({ transform: 'translateY(0%)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0%)', opacity: 1 }),
        animate('.5s ease-in',
          style({ transform: 'translateY(-100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup = new FormGroup({});
  obscurePwd: boolean = true;
  configRutas: { [index: string]: any } = data;

  constructor(
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private alerts: AlertService,
    private router: Router,
    private formatter: FormatterService,
    private sesion: SesionService,
    private sucursalService: SucursalService,
    private modalService: NgbModal,
    private mdService: MarkdownService,
    private sentryService: SentryService,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      password: [null, [Validators.required]],
      email: [null, Validators.required]
    });
    this.isLogged();
  }

  iniciarSesion(): void {
    const emailControlClear = this.loginForm.get('password');
    emailControlClear?.setErrors(null);
    if (!this.loginForm.valid) {
      this.loginForm.markAllAsTouched();
      this.alerts.addAlert("Hay errores en el formulario.", "warning");
      return;
    }
    this.loading.show();
    this.sesion.login(this.loginForm.value).subscribe({
      next: (res) => {
        this.loading.hide();
        if (res.password_activado == 0 || res.ultimo_inicio_sesion == null) {
          this.loadDataAfterLogin(false);
          if (res.ultimo_inicio_sesion === null) {
            this.router.navigate(['ayuda']);
          } else if (res.rol_id == this.sesion.ROL_ADMINISTRADOR) {
            this.router.navigate(['empresa/registrar']);
          } else {
            this.goHome();
          }
          if (res.password_activado == 0) {
            this.alerts.addAlert("Bienvenido " + this.formatter.nombreCompleto(this.sesion.user), "success");
            const modalRef = this.modalService.open(UsuarioPassChangeComponent, { centered: true });
            modalRef.componentInstance.primeraVez = true;
            modalRef.componentInstance.oldPass = this.loginForm.value.password;
            modalRef.result.then((result: any) => { }, (reason: any) => { });
          }
        } else {
          this.loadDataAfterLogin(true);
          this.loading.hide();
          this.alerts.addAlert("Bienvenido " + this.formatter.nombreCompleto(this.sesion.user), "success");
        }
      },
      error: (err) => {
        console.error(err);
        this.loading.hide();
        if (err.error.internalCode == 1 && err.error.mensaje == 'El nombre de usuario o la contraseña son incorrectos.') {
          const emailControl = this.loginForm.get('password');
          emailControl?.setErrors({ customError: 'El correo electrónico y/o la contraseña son incorrectos.' });
        }
        this.alerts.addAlert("No se ha podido iniciar sesión.", "danger");
      }
    });
  }

  goHome(): void {
    this.sesion.goToHome();
  }

  recuperarContrasena(): void {
    this.router.navigate(['recuperar-password']);
  }

  asignarRutas(goHome: boolean): void {
    this.loading.show();
    this.sesion.getHomeOptions().subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.sesion.routes = res.routes;
        this.sesion.actualizarElementosBuscadorMenu(res.routes);
        if (goHome) this.goHome();
      },
      error: (err: any) => {
        this.loading.hide();
        if (err.response) this.alerts.addAlert(err.response, 'danger');
      }
    });
  }

  loadDataAfterLogin(goHome: boolean): void {
    this.asignarRutas(goHome);
    this.cargarVersionUpdate();
    this.getSucursales();
    if (environment.habilitarSuscripciones && this.sesion.user.rol_id != 0) this.checkPaymentState();
  }


  isLogged(): void {
    if (window.location.pathname === '/terminos') return;
    if (window.location.pathname === '/recuperar-password') return;
    if (window.location.pathname.includes('/restablecer')) return;
    if (this.sesion.token) {
      this.loading.show();
      this.sesion.isLogged().subscribe({
        next: (res) => {
          this.sesion.saveSesion(this.sesion.token ? this.sesion.token : res.token, res.usuario);
          this.loading.hide();
          this.router.navigate(['/']);
          this.alerts.addAlert("Bienvenido de nuevo " + this.formatter.nombreCompleto(res.usuario), "info");
          this.loadDataAfterLogin(true);
          this.sentryService.setUser(res.usuario.email, res.usuario.empresa_id);
        },
        error: (err) => {
          console.error(err);
          this.sesion.clearSesion();
          this.router.navigate(['login']);
          this.sentryService.setUser(null, null);
          this.loading.hide();
          this.alerts.addAlert("No se ha podido reiniciar sesión.", "danger");
        }
      });
    } else {
      this.router.navigate(['login']);
      this.sentryService.setUser(null, null);
    }
  }

  checkPaymentState(): void {
    this.loading.show();
    this.sesion.getPaymentState().subscribe({
      next: (res: any) => {
        this.loading.hide();
        if (!res.suscripcion_activa) {
          this.loading.show()
          this.sesion.checkPaymentState().subscribe({
            next: (res: any) => {
              this.loading.hide();
              const modalRef = this.modalService.open(SuscripcionesPagoPendienteComponent, { centered: true, backdrop: 'static' });
              modalRef.result.then((result: any) => {
                if (result.cerrarSesion) {
                  this.logout();
                  return;
                } else {
                  this.checkPaymentState();
                  return;
                }
              }, (reason: any) => {
                this.checkPaymentState();
                return
              });
            },
            error: (err: any) => {
              this.loading.hide();
              if (err.response) this.alerts.addAlert(err.response, 'danger');
              return
            }
          });
        }
        return
      },
      error: (err: any) => {
        this.loading.hide();
        if (err.response) this.alerts.addAlert(err.response, 'danger');
        return
      }
    });
  }

  cargarVersionUpdate(): void {
    this.sesion.getLatestVersion().subscribe({
      next: (version: any) => {
        if (!version) return;
        if (localStorage.getItem('latest-version') != version) {
          localStorage.setItem('latest-version', version);
          this.sesion.getLatestVersionMd().subscribe({
            next: (resMD: any) => {
              let md = this.mdService.compile(resMD);
              const modalRef = this.modalService.open(VersionUpdateComponent, { size: 'md', scrollable: true, windowClass: 'modal-version-update' });
              modalRef.componentInstance.md = md;
              modalRef.componentInstance.version = version;
              modalRef.result.then((result: any) => {
                location.reload();
              }, (reason: any) => {
                location.reload();
              });
            },
            error: (err: any) => { }
          });
        }
      },
      error: (err: any) => { }
    });
  }

  logout(): void {
    this.loading.show();
    this.sesion.clearSesion();
    this.loading.hide();
    this.router.navigate(['login']);
  }

  getSucursales(): void {
    if (!this.sesion.token) return;
    this.sucursalService.misSucursales().subscribe({
      next: (res) => {
        this.sesion.sucursales = res.sucursales;
        if (this.sesion.sucursales.length != 0) {
          if (this.sesion.sucursal?.id != this.sesion.sucursales[0].id) {
            localStorage.setItem('sucursal', JSON.stringify(this.sesion.sucursales[0]));
            localStorage.setItem('sucursal_id', `${this.sesion.sucursales[0].id}`);
            this.sesion.setSucursal(this.sesion.sucursales[0]);
            let sucursal = this.sesion.sucursal;
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == sucursal.id);
          } else {
            let sucursal = this.sesion.sucursal;
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == sucursal.id);
          }
        }
      },
      error: (err) => {
        console.error(err);
        this.sesion.clearSesion();
        this.loading.hide();
      }
    });
  }

  changeObscurePwd(): void {
    this.obscurePwd = !this.obscurePwd;
  }

  soporte(): void {
    this.router.navigate(['soporte']);
  }

  tos(): void {
    this.router.navigate(['terminos']);
  }
}
