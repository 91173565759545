import { Injectable } from '@angular/core';
import { AlertService } from 'ngx-kuv-tools';
import { SesionService } from '../sesion/sesion.service';
import { DateService } from '../date/date.service';
import { ID_PAIS_CHILE } from '../pais/util';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class FormatterService {
  RUT_MASK = '0.000.000-A||90.000.000-A';
  DOS_DECIMALES_MASK = 'separator.3';
  CERO_DECIMALES_MASK = 'separator.0';

  constructor(
    private sesion: SesionService,
    private alerts: AlertService,
    private dateService: DateService,
    private helperService: HelperService,
  ) { }

  /**
   * Cambia el formato de una fecha de "yyyy-mm-dd HH:ii:ss" a "dd-mm-yyyy HH:ii:ss"
   */
  timestampFormat(fecha: string, showSeconds = true): string {
    if (fecha) {
      return fecha.substr(8, 2) + "-" + fecha.substr(5, 2) + "-" + fecha.substr(0, 4) + " " + (showSeconds ? fecha.substr(11, 8) : fecha.substr(11, 5));
    } else {
      return fecha;
    }
  }

  /**
   * Cambia el formato de una fecha de "yyyy-mm-dd" a "dd-mm-yyyy"
   */
  dateFormat(fecha: string) {
    if (fecha) {
      return fecha.substr(8, 2) + "-" + fecha.substr(5, 2) + "-" + fecha.substr(0, 4);
    } else {
      return fecha;
    }
  }
  /**
   * Cambia el formato de una fecha de "dd-mm-yyyy" a "yyyy-mm-dd"
   */
  usaDateFormat(fecha: string) {
    if (fecha) {
      return fecha.substr(6, 4) + "-" + fecha.substr(3, 2) + "-" + fecha.substr(0, 2);
    } else {
      return fecha;
    }
  }

  /**
    * Valida si el valor del carácter presionado es un número mayor o igual a cero.
    *
    * @param {KeyboardEvent} event - El evento del teclado.
    */
  validatePositiveNumber(event: KeyboardEvent) {
    // Obtener el valor del carácter presionado
    const char = event.key;

    // Verificar si el valor es un número mayor o igual a cero
    if (char.match(/[0-9]/) && Number(char) >= 0) {
      // El valor es válido, puedes procesarlo como desees
    } else {
      // El valor es inválido, puedes mostrar un mensaje de error o impedir que se ingrese
      event.preventDefault();
    }
  }

  /**
 * Valida si el valor del carácter presionado es un número.
 *
 * @param {KeyboardEvent} event - El evento del teclado.
 */
  validateAllNumber(event: KeyboardEvent) {
    // Obtener el valor del carácter presionado
    const char = event.key;

    // Verificar si el valor es un número o el signo -
    if (char.match(/[0-9-]/)) {
      // El valor es válido, puedes procesarlo como desees
    } else {
      // El valor es inválido, puedes mostrar un mensaje de error o impedir que se ingrese
      event.preventDefault();
    }
  }

  validarRut(rut: string) {

    if (rut.length === 0) return false;

    let rutDigits = parseInt(rut.slice(0, -1), 10);
    let m = 0;
    let s = 1;
    while (rutDigits > 0) {
      s = (s + rutDigits % 10 * (9 - m++ % 6)) % 11;
      rutDigits = Math.floor(rutDigits / 10);
    }
    const checkDigit = (s > 0) ? String((s - 1)) : 'K';

    return checkDigit === rut.slice(-1)
  }

  validarDocumentoIdentidad(documentoIdentidad: string, idPais?: string | number): boolean {
    const pais = idPais ? idPais : this.sesion.user?.pais_id;
    switch (pais) {
      case ID_PAIS_CHILE:
        return this.validarRut(documentoIdentidad);
      default:
        return true;
    }
  }

  // Funciona independiente del largo
  formatoRUT(rut: string) { // String con formato "12345678k"
    if (!rut) return '';
    let digit = rut.slice(-1);
    let num = rut.slice(0, -1);
    let result = '';
    while (num.length > 0) {
      if (result != '') result = '.' + result;
      result = num.slice(-3) + result;
      num = num.slice(0, -3)
    }
    return result + '-' + digit;
  }

  /**
   * Retorna la concatenación de nombre y apellido
   * @param persona Object el objeto que tiene nombre y apellido
   * @param nombre string el identificador del nombre
   * @param apellido string el identificador del apellido
   * @returns
   */
  nombreCompleto(persona: any, nombre = "nombre", apellido = "apellido") {
    let palabras = [];
    if (!persona) return "";
    if (persona[nombre]) palabras.push(persona[nombre]);
    if (persona[apellido]) palabras.push(persona[apellido]);
    return palabras.join(' ');
  }

  esRol(expectedRol: number) {
    let rol = this.sesion.rol;
    return rol == 1 || rol == 2 || rol == expectedRol;
  }

  esRolExclusivo(expectedRol: number) {
    let rol = this.sesion.rol;
    return rol == expectedRol;
  }

  splitString(text: string, length: number = 100, limit: number = 100): Array<string> {
    if (!text) return [''];
    if (limit == 0) return [''];
    if (text.length < length) return [text];
    return [text.substr(0, length)].concat(this.splitString(text.substr(length), length, limit--));
  }

  capitalize(texto: string) {
    return texto.substring(0, 1).toUpperCase() + texto.substring(1);
  }

  stringToComparableNumber(text: string, length: number = 10) {
    let maxCode = '000';
    // if (!text) return new Array(length).fill(maxCode).join('');
    if (!text) text = '0';
    let array = text.split('');
    if (array.length < length) array = new Array(length - array.length).fill('0').concat(array);
    let indexOfMin = array.findIndex(e => e == '-');
    if (indexOfMin != -1 && indexOfMin != 0) {
      let aux = array[indexOfMin];
      array.splice(indexOfMin, 1);
      array = [aux].concat(array);
    }
    let inverse = 0;
    array = array.map(e => {
      let res = '';
      if (e == '-') {
        inverse = 999;
        return '000';
      }
      res = Math.abs(inverse - e.charCodeAt(0)).toString();
      let aux = res.split('');
      if (aux.length < 3) aux = new Array(3 - aux.length).fill('0').concat(aux);
      return aux.join('');
    });
    return array.join('');
  }

  /**
   * Función que entrega formato a un número.
   * @param num Número sin formato
   * @param minDecimal Cantidad mínima de decimales, default 0
   * @param maxDecimal Cantidad máxima de decimales, default 2
   * @param errMsg Texto en caso de error
   * @returns El número con formato 00.000.000,00
   */
  numberFormat(num: number, minDecimal: number = 0, maxDecimal: number = 2, errMsg: string = 'N.D.', locale: string = 'es-CL'): string {
    try {
      let valor = (num - 0)
      if (isNaN(valor)) return `${num}`;
      return valor.toLocaleString(locale, { minimumFractionDigits: minDecimal, maximumFractionDigits: maxDecimal });;
    } catch (error) {
      console.error(error);
      return errMsg;
    }
  }

  showErrorAlerts(error: any): void {
    if (error.response) this.alerts.addAlert(error.response, 'danger');
    if (error.errors) {
      Object.keys(error.errors).forEach(key => {
        error.errors[key].forEach((element: any) => {
          this.alerts.addAlert(element, 'danger');
        });
      })
    }
  }

  /**
   * Valida si el valor del carácter presionado es un número mayor o igual a cero.
   *
   * @param {KeyboardEvent} event - El evento del teclado.
   */
  validateNumber(event: KeyboardEvent) {
    // Obtener el valor del carácter presionado
    const char = event.key;

    // Verificar si el valor es un número mayor o igual a cero
    if (char.match(/[0-9]/) && Number(char) >= 0) {
      // El valor es válido, puedes procesarlo como desees
    } else {
      // El valor es inválido, puedes mostrar un mensaje de error o impedir que se ingrese
      event.preventDefault();
    }
  }

  /**
   * Convierte un arreglo de elementos en 2 arreglos para la leyenda y los valores de un reporte por fecha
   * @param data Arreglo de elementos con {dia, mes, anio y total}
   * @param desde desde que fecha
   * @param hasta hasta que fecha
   * @param tipoVista 1-Dia, 2-Mes, 3-Año
   * @param valor El nombre del parámetro que tiene el valor para el resultado
   * @returns {leyenda: string[], result: number[]} leyenda representa las fechas y result los valores
   */
  parseDataParaReporte(data: any, desde: string, hasta: string, tipoVista: number, valor: string = 'total', mantenerValor: boolean = false) {
    let leyenda: any[] = [];
    let result: any[] = [];
    // Definir límites
    let desdeDate = new Date(desde);
    let hastaDate = new Date(hasta);
    let index = 0; // Para recorrer la data
    let lastTotal = 0;
    let anio = desdeDate.getFullYear();
    let mes = (desdeDate.getMonth() - 0) + 1;
    let dia = desdeDate.getDate();
    while (mantenerValor && index < data.length && `${data[index].anio}-${data[index].mes}-${data[index].dia}` < `${anio}-${mes}-${dia}`) {
      lastTotal += data[index][valor] - 0;
      index++;
    }
    while (desdeDate < hastaDate) {
      let total = 0;
      let cantidadElementos = 0;
      // Definir Variables
      let anio = desdeDate.getFullYear();
      let mes = (desdeDate.getMonth() - 0) + 1;
      let dia = desdeDate.getDate();
      // Asignar leyenda
      let legend = `${anio}`;
      if (tipoVista == 1) legend = `${dia < 10 ? '0' + dia : dia}-${mes < 10 ? '0' + mes : mes}-${anio}`
      else if (tipoVista == 2) legend = `${mes < 10 ? '0' + mes : mes}-${anio}`
      // Obtengo el elemento actual
      let element = data[index];
      // Defino la consulta a utilizar
      let consulta = false;
      if (element) {
        consulta = element.anio == anio;
        if (tipoVista == 1) consulta = element.anio == anio && element.mes == mes && element.dia == dia;
        else if (tipoVista == 2) consulta = element.anio == anio && element.mes == mes;
      }
      // Mientras la consulta sea válida, se recorre la data
      while (consulta) {
        total += (element[valor] - 0);
        cantidadElementos++;
        index++;
        element = data[index]; // Obtengo el elemento actual
        consulta = false;
        if (element) {
          consulta = element.anio == anio;
          if (tipoVista == 1) consulta = element.anio == anio && element.mes == mes && element.dia == dia;
          else if (tipoVista == 2) consulta = element.anio == anio && element.mes == mes;
        }
      }
      leyenda.push(legend);
      if (cantidadElementos == 0 && mantenerValor) total = lastTotal;
      if (cantidadElementos != 0 && mantenerValor) total = total + lastTotal;
      result.push(total);
      lastTotal = total;
      mes--;
      if (tipoVista == 1) dia++;
      else if (tipoVista == 2) mes++;
      else anio++;
      desdeDate = new Date(anio, mes, dia, 12, 0, 0);
    }

    return { leyenda, result }
  }

  dataURItoBlob(dataURI: string, type: string = 'image/png') {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: type });
    return blob;
  }

  obtenerFiltroFecha(tipo: any = null) {
    let dateHoy = new Date();
    let dia = dateHoy.getDate();
    let mes = (dateHoy.getMonth() - 0) + 1;
    let anio = dateHoy.getFullYear();
    let hora = dateHoy.getHours();
    let minuto = dateHoy.getMinutes();
    let filtroFecha = tipo;
    let desde: string = '';
    let hasta: string = '';
    switch (tipo) {
      case 1:

        break;
      case 2: // Este día
        desde = `${anio}-${mes < 10 ? '0' + mes : mes}-${dia < 10 ? '0' + dia : dia} 00:00`;
        hasta = `${anio}-${mes < 10 ? '0' + mes : mes}-${dia < 10 ? '0' + dia : dia} 23:59`;
        break;
      case 3: // Este mes
        desde = `${anio}-${mes < 10 ? '0' + mes : mes}-01 00:00`;
        hasta = `${new Date(anio, mes, 0, 12, 0, 0).toISOString().substring(0, 10)} 23:59`;
        break;
      case 4: // Mes pasado
        desde = `${anio}-${mes - 1 < 10 ? '0' + (mes - 1) : mes - 1}-01 00:00`;
        hasta = `${new Date(anio, mes - 1, 0, 12, 0, 0).toISOString().substring(0, 10)} 23:59`;
        break;
      case 5: // Este año
        desde = `${anio}-01-01 00:00`;
        hasta = `${anio}-12-31 23:59`;
        break;
      case 6: // Año pasado
        desde = `${anio - 1}-01-01 00:00`;
        hasta = `${anio - 1}-12-31 23:59`;
        break;
      case 7: // Todo el tiempo
        desde = `1970-01-01 00:00`;
        hasta = `2099-12-31 23:59`;
        break;
      case 8: // Esta semana
        let startWeek = this.dateService.firstDayOfWeek();
        let endWeek = this.dateService.addTime('+6 days', this.dateService.firstDayOfWeek());
        desde = `${this.dateService.format(startWeek, 'yyyy-mm-dd')} 00:00`;
        hasta = `${this.dateService.format(endWeek, 'yyyy-mm-dd')} 23:59`;
        break;
      default:
        break;
    }

    return { desde, hasta };
  }

  textLimit(texto: string, cantidad: number = 100) {
    if (!texto) return texto;
    if (texto.length < cantidad - 3) return texto;
    return texto.substring(0, cantidad - 3) + '...';
  }

  mapArray(element: number, array: any[], offset: number = 0) {
    let index = element - 0 + offset - 0;
    if (index > array.length || index < 0) return null;
    return array[index]
  }

  labelMetodoPago(tipo: number) {
    return this.mapArray(tipo, ['Efectivo', 'T.Débito', 'T.Crédito', 'Transferencia', 'Cheque', 'Crédito'], -1);
  }

  labelTipoDocumento(tipo: number) {
    return this.mapArray(tipo, ['Boleta', 'Factura', 'Cotización'], -1);
  }

  aplicarDescuento(monto: number, descuento: number, tipo_descuento: 1 | 2 = 1) {
    return monto - (tipo_descuento == 1 ? descuento : this.helperService.redondearDecimalesSegunPais(monto * descuento / 100));
  }

  numeroDTEToString(dte: number): string {
    switch (dte) {
      case 30: return 'Factura';
      case 32: return 'Factura Compra';
      case 33: return 'Factura Electrónica';
      case 34: return 'Factura no Afecta Electrónica';
      case 35: return 'Boleta';
      case 39: return 'Boleta electrónica';
      case 41: return 'Boleta no Afecta Electrónica';
      case 46: return 'Factura Compra Electrónica';
      case 50: return 'Guía de Despacho';
      case 52: return 'Guía de Despacho Electrónica';
      case 56: return 'Nota de Débito';
      case 55: return 'Nota de Crédito';
      case 56: return 'Nota de Débito Electrónica';
      case 61: return 'Nota de Crédito Electrónica';
      case 103: return 'Liquidación Factura';
      case 110: return 'Liquidación Factura Electrónica';
      case 801: return 'Orden de Compra';
      case 802: return 'Nota de pedido';
      case 803: return 'Contrato';
      case 804: return 'Resolución';
      case 805: return 'Proceso ChileCompra';
      case 806: return 'Ficha ChileCompra';
      default: return '';
    }
  }

  unidadesProducto(producto: any): number | null {
    if (producto.tipo == 2) {
      return null;
    }
    return producto.stock_local;
  }


  labelDocumentoIdentidad(idPais?: string | number) {
    const pais = idPais ? idPais : this.sesion.user?.pais_id;
    switch (pais) {
      case ID_PAIS_CHILE:
      case null:
      case undefined:
        return 'RUT';
      default:
        return 'DNI';
    }
  }

  labelDocumentoIdentidadEmpresa(idPais?: string | number) {
    const pais = idPais ? idPais : this.sesion.user?.pais_id;
    switch (pais) {
      case ID_PAIS_CHILE:
        return 'RUT';
      case null:
        return 'RUT';
      case undefined:
        return 'RUT';
      default:
        return 'N° Identificación Fiscal';
    }
  }

  formatoDocumentoIdentidad(documentoIdentidad: string, idPais?: string | number) {
    if (!documentoIdentidad) {
      return '';
    }
    const pais = idPais ? idPais : this.sesion.user?.pais_id;
    switch (pais) {
      case ID_PAIS_CHILE:
      case null:
      case undefined:
        return this.formatoRUT(documentoIdentidad);
      default:
        return documentoIdentidad;
    }
  }

  maskDocumentoIdentidad(idPais?: string | number) {
    const pais = idPais ? idPais : this.sesion.user?.pais_id;
    switch (pais) {
      case ID_PAIS_CHILE:
      case null:
      case undefined:
        return this.RUT_MASK;
      default:
        return '';
    }
  }

  numberFormatMonedaPais(num: number, errorMsg?: string) {
    if (this.sesion.esPaisChile()) {
      return this.numberFormat(num, 0, 0, errorMsg);
    }
    return this.numberFormat(num, 2, 2, errorMsg);
  }

  maskDecimalInput(idPais?: string | number) {
    const pais = idPais ? idPais : this.sesion.user?.pais_id;
    switch (pais) {
      case ID_PAIS_CHILE:
      case null:
      case undefined:
        return this.CERO_DECIMALES_MASK;
      default:
        return this.DOS_DECIMALES_MASK;
    }
  }

  genUID(){
    const stringArr = ['id'];
    for (let i = 0; i < 20; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }
}
