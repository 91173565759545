<div class="w-100 contenido">
    <div class="d-flex justify-content-center">
        <h5 class="text">Bienvenido al proceso de suscripción Lubrikuv</h5>
    </div>

    <div class="d-flex justify-content-center">
        <img [src]="'/assets/system/logo_sidebar.png'" class="imagen">
    </div>
    <br>
    <div class="d-flex justify-content-center">
        <p class="text">Antes de continuar, debe leer y aceptar los términos y condiciones de Lubrikuv para
            suscribirse al plan indicado.</p>
    </div>
    <hr>
    <div class="d-flex justify-content-center" style="text-align: start;">
        <markdown [data]="md"></markdown>
    </div>
    <div style="margin-bottom: 150px;"></div>
</div>

<div class="fixed-buttons">
    <div class="d-flex justify-content-center mb-2">
        <label class="text">El proceso de suscripción será realizado via <b>{{paisID == 46 ? 'VentiPay' :
                'dLocalGo'}}</b>.</label>
    </div>
    <div class="d-flex justify-content-center mb-2">
        <label class="text">Suscripción Lubrikuv <b>Plan {{this.planFormateado}}
                {{this.intervaloPlanFormateado}}</b>.</label>
    </div>
    <div class="d-flex justify-content-center mb-2" *ngIf="montoParcial != 0">
        <label class="text-bold">El primer mes contará con un cobro parcial de
                {{currency}} {{this.formatPrice(montoParcial)}}.</label>
    </div>
    <div class="mb-2"></div>
    <div class="d-flex justify-content-center w-100 gap-3">
        <button type="button" class="btn btn-outline-danger w-50" (click)="cancelar()">Rechazar</button>
        <button type="button" class="btn btn-primary w-50" (click)="aceptar()">Aceptar y Continuar</button>
    </div>
</div>