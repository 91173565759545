<!-- For camera -->
<ngx-scanner-qrcode #action="scanner"></ngx-scanner-qrcode>

<!-- data  -->
<span>{{ action.data.value | json }}</span><!-- value -->
<span>{{ action.data | async | json }}</span><!-- async -->

<!-- Loading -->
<p *ngIf="action.isLoading">⌛ Loading...</p>

<!-- start -->
<button (click)="action.isStart ? action.stop() : action.start()">{{action.isStart ? 'Stop' : 'Start'}}</button>