<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <h4 *ngIf="!generarEnlace" class="d-flex justify-content-center mb-4" style="text-align: center;">
        Seleccione uno de nuestros planes{{ planPrevio ? ' para actualizar' : ''}}
    </h4>

    <h4 *ngIf="generarEnlace" class="d-flex justify-content-center mb-4" style="text-align: center;">
        Seleccione un plan para generar el enlace de suscripción.
    </h4>

    <div class="d-flex justify-content-center mb-4"><img [src]="'/assets/system/logo_sidebar.png'" width="200px">
    </div>

    <div class="btn-group mb-3 w-100 mb-4" role="group" aria-label="Basic example">
        <button type="button" class="btn" (click)="seleccionarPeriodo('MENSUAL')"
            [ngClass]="{'btn-primary ': periodo == 'MENSUAL', 'btn-light border': periodo != 'MENSUAL'}">Mensual</button>
        <button type="button" class="btn" (click)="seleccionarPeriodo('ANUAL')"
            [ngClass]="{'btn-primary ':  periodo == 'ANUAL', 'btn-light border': periodo != 'ANUAL'}">Anual</button>
    </div>

    <div class="d-flex justify-content-between flex-wrap flex-xl-nowrap mb-4 gap-4">

        <div class="card w-100"
            [ngClass]="{'active': plan == 'FILTRO_X', 'inactive': plan != 'FILTRO_X', 'disabled': isDowngradeFiltroX, 'card-plan-actual': planPrevioSeleccionado == 'FILTRO_X'}"
            (click)="seleccionarPlan('FILTRO_X')">
            <div class="d-flex flex-column justify-content-between h-100 align-items-center">
                <div class="d-flex flex-column justify-content-between align-items-center mb-4">
                    <div class="title">FILTRO X</div>
                    <div class="monto">
                        <div [ngClass]="{'monto-descuento': montoFiltroXAlt}">
                            {{currency}} {{formatPrice(montoFiltroX)}}
                        </div>
                        <div *ngIf="esPaisChile" class="iva">+ IVA</div>
                    </div>

                    <div class="d-flex align-items-center"
                        *ngIf="planPrevio && planPrevioSeleccionado != 'FILTRO_X' && montoFiltroXAlt">
                        Actualiza por &nbsp;
                        <div class="monto-alt" *ngIf="montoFiltroXAlt">
                            {{currency}} {{formatPrice(montoFiltroXAlt)}}
                            <div *ngIf="esPaisChile" class="iva"> + IVA</div>
                        </div>
                        &nbsp;(*)
                    </div>

                    <div *ngIf="planPrevioSeleccionado == 'FILTRO_X'" class="text">
                        <div class="texto-plan-actual">Plan Actual</div>
                    </div>

                    <div *ngIf="periodo != 'ANUAL'" class="text">
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                    </div>
                    <div *ngIf="periodo == 'ANUAL'" class="text">
                        <div>Ahorra {{currency}} {{formatPrice(montoAhorroFiltroXYear)}}</div>
                        <div><b>2 Meses Gratis</b></div>
                    </div>
                </div>
                <div class="d-flex flex-column h-100">
                    <div class="text">Ventas</div>
                    <div class="text">Órdenes de Trabajo</div>
                    <div class="text">Inventario</div>
                    <div class="text">Soporte</div>
                </div>
                <div *ngIf="!planPrevio && periodo != 'ANUAL' && esPaisChile">
                    <div class="text mt-3" style="font-size: 16px;">
                        {{currency}} {{formatPrice(montoParcialFiltroX)}} el primer mes (*)
                    </div>
                </div>
                <div *ngIf="planPrevio && isDowngradeFiltroX" class="text">
                    <div class="text mt-3" style="font-size: 12px;">
                        (**) Contáctese con ejecutivo/a de ventas
                    </div>
                </div>
                <div *ngIf="planPrevio && !isDowngradeFiltroX" class="text">
                    <div>&nbsp;</div>
                </div>
                <div class="mt-3" *ngIf="planPrevioSeleccionado == 'FILTRO_X'"><b>Plan Actual</b></div>
                <div class="mt-3" *ngIf="planPrevioSeleccionado != 'FILTRO_X'">&nbsp;</div>
            </div>
        </div>

        <div class="card w-100"
            [ngClass]="{'active': plan == 'ACELERA', 'inactive': plan != 'ACELERA' , 'disabled': isDowngradeAcelera, 'card-plan-actual': planPrevioSeleccionado == 'ACELERA'}"
            (click)="seleccionarPlan('ACELERA')">
            <div class="d-flex flex-column justify-content-between h-100 align-items-center">
                <div class="d-flex flex-column justify-content-between align-items-center mb-4">
                    <div class="title">ACELERA+</div>

                    <div class="monto">
                        <div [ngClass]="{'monto-descuento': montoAceleraAlt}">
                            {{currency}} {{formatPrice(montoAcelera)}}
                        </div>
                        <div *ngIf="esPaisChile" class="iva">+ IVA</div>
                    </div>

                    <div class="d-flex align-items-center"
                        *ngIf="planPrevio && planPrevioSeleccionado != 'ACELERA' &&  montoAceleraAlt">
                        Actualiza por &nbsp;
                        <div class="monto-alt" *ngIf="montoAceleraAlt">
                            {{currency}} {{formatPrice(montoAceleraAlt)}}
                            <div *ngIf="esPaisChile" class="iva"> + IVA</div>
                        </div>
                        &nbsp;(*)
                    </div>

                    <div *ngIf="planPrevioSeleccionado == 'ACELERA'" class="text">
                        <div class="texto-plan-actual">Plan Actual</div>
                    </div>

                    <div *ngIf="periodo != 'ANUAL'" class="text">
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                    </div>
                    <div *ngIf="periodo == 'ANUAL'" class="text">
                        <div>Ahorra {{currency}} {{formatPrice(montoAhorroAceleraYear)}}</div>
                        <div><b>2 Meses Gratis</b></div>
                    </div>
                </div>
                <div class="d-flex flex-column h-100">
                    <div class="text">Todas las funciones de Filtro X</div>
                    <div class="text">Reportes</div>
                    <div class="text">Clientes</div>
                    <div class="text">Cobranza</div>
                    <div class="text">Vehículos</div>
                    <div class="text">Servicios</div>
                    <div class="text">Cotizaciones</div>

                </div>
                <div *ngIf="!planPrevio && periodo != 'ANUAL' && esPaisChile">
                    <div class="text mt-3" style="font-size: 16px;">
                        {{currency}} {{formatPrice(montoParcialAcelera)}} el primer mes (*)
                    </div>
                </div>
                <div *ngIf="planPrevio && isDowngradeAcelera" class="text">
                    <div class="text mt-3" style="font-size: 12px;">
                        (**) Contáctese con ejecutivo/a de ventas
                    </div>
                </div>
                <div *ngIf="planPrevio && !isDowngradeAcelera" class="text">
                    <div>&nbsp;</div>
                </div>
                <div class="mt-3" *ngIf="planPrevioSeleccionado == 'ACELERA'"><b>Plan Actual</b></div>
                <div class="mt-3" *ngIf="planPrevioSeleccionado != 'ACELERA'">&nbsp;</div>
            </div>
        </div>

        <div class="card w-100"
            [ngClass]="{'active': plan == 'TURBO', 'inactive': plan != 'TURBO' , 'disabled': isDowngradeTurbo &&  planPrevioSeleccionado != 'TURBO', 'card-plan-actual': planPrevioSeleccionado == 'TURBO'}"
            (click)="seleccionarPlan('TURBO')">
            <div class="d-flex flex-column justify-content-between h-100 align-items-center">
                <div class="d-flex flex-column justify-content-between align-items-center mb-4">
                    <div class="title">TURBO</div>

                    <div class="monto">
                        <div [ngClass]="{'monto-descuento': montoTurboAlt}">
                            {{currency}} {{formatPrice(montoTurbo)}}
                        </div>
                        <div *ngIf="esPaisChile" class="iva">+ IVA</div>
                    </div>

                    <div class="d-flex align-items-center"
                        *ngIf="planPrevio && planPrevioSeleccionado != 'TURBO' && montoTurboAlt">
                        Actualiza por &nbsp;
                        <div class="monto-alt" *ngIf="montoTurboAlt">
                            {{currency}} {{formatPrice(montoTurboAlt)}}
                            <div *ngIf="esPaisChile" class="iva"> + IVA</div>
                        </div>
                        &nbsp;(*)
                    </div>

                    <div *ngIf="planPrevioSeleccionado == 'ANUAL'" class="text">
                        <div class="texto-plan-actual">Plan Actual</div>
                    </div>

                    <div *ngIf="periodo != 'ANUAL'" class="text">
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                    </div>

                    <div *ngIf="periodo == 'ANUAL'" class="text">
                        <div>Ahorra {{currency}} {{formatPrice(montoAhorroTurboYear)}}</div>
                        <div><b>2 Meses Gratis</b></div>
                    </div>
                </div>
                <div class="d-flex flex-column h-100">
                    <div class="text">Todas las funciones de Acelera+</div>
                    <div class="text">Gastos</div>
                    <div class="text">Compras</div>
                    <div class="text">Proveedores</div>
                    <div class="text">Anuncios Publicitarios</div>
                    <div class="text">App Móvil para sus clientes</div>
                    <div class="text">Reserva de Citas</div>
                </div>
                <div *ngIf="!planPrevio && periodo != 'ANUAL' && esPaisChile">
                    <div class="text mt-3" style="font-size: 16px;">
                        {{currency}} {{formatPrice(montoParcialTurbo)}} el primer mes (*)
                    </div>
                </div>
                <div *ngIf="planPrevio && isDowngradeTurbo" class="text">
                    <div class="text mt-3" style="font-size: 12px;">
                        (**) Contáctese con ejecutivo/a de ventas
                    </div>
                </div>
                <div *ngIf="planPrevio && !isDowngradeTurbo" class="text  mt-3">
                    <div>&nbsp;</div>
                </div>
                <div class="mt-3" *ngIf="planPrevioSeleccionado == 'TURBO'"><b>Plan Actual</b></div>
                <div class="mt-3" *ngIf="planPrevioSeleccionado != 'TURBO'">&nbsp;</div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center w-100 mb-4 text-center">
        <h6 class="fst-italic" style="font-size: 16px;" *ngIf="!planPrevio && periodo != 'ANUAL' && esPaisChile">
            <p>(*) El primer mes se realizará un cobro proporcional por los días hasta el primer día del próximo mes
                ({{dias}} día{{dias == 1 ? '' : 's'}}).</p>
        </h6>
        <h6 class="fst-italic" style="font-size: 16px;" *ngIf="planPrevio && esPaisChile">
            <p>(*) Valor por el primer mes. Luego se cobrará tarifa mensual regular indicada por el plan suscrito.</p>
            <p> (**) Para cambiar de plan a uno inferior, por favor contactar con su ejecutivo/a de ventas.</p>
        </h6>
        <h6 class="fst-italic" style="font-size: 16px;" *ngIf="planPrevio && !esPaisChile">
            <p>(*) Para cambiar de plan, por favor contactar con su ejecutivo/a de ventas.</p>
        </h6>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-primary w-100 py-2" [disabled]="!plan" type="button" (click)="continueButton()">
        <b>{{textoSubmitButton}}</b>
    </button>
    <div class="d-flex justify-content-center w-100  mt-2 gap-4">
        <button class="btn btn-outline-secondary w-50 py-2" type="button" (click)="helpButton()">
            <b>Solicitar Ayuda</b>
        </button>
        <button class="btn btn-outline-secondary w-50 py-2" type="button" (click)="activeModal.close()">
            <b>Cancelar</b>
        </button>
    </div>
</div>