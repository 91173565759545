import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { EncuestaNPSService } from '../encuesta-nps.service';
import { SentryService } from 'src/app/utils/sentry/sentry.service';
import { SesionService } from 'src/app/utils/sesion/sesion.service';
import { DateService } from 'src/app/utils/date/date.service';

@Component({
  selector: 'app-encuesta-nps-create',
  templateUrl: './encuesta-nps-create.component.html',
  styleUrls: ['./encuesta-nps-create.component.scss']
})
export class EncuestaNpsCreateComponent implements OnInit {
  form: FormGroup = new FormGroup({});
  recomendaciones = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  experienciaCaracteres = 1500;
  formularioFinalizado = false;

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private alerts: AlertService,
    private loading: LoadingService,
    private service: EncuestaNPSService,
    private sentry: SentryService,
    private sesion: SesionService,
    private date: DateService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      recomendacion: [null, [Validators.required]],
      experiencia: ['', [Validators.required, Validators.maxLength(this.experienciaCaracteres)]],
    });
  }

  clickBotonRecomendacion(event: Event, valor: number) {
    this.form.patchValue({
      recomendacion: valor
    });
  }

  guardar() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.alerts.addAlert('Hay errores en el formulario', 'danger');
      return;
    }
    this.loading.show();
    this.service.insert(this.form.value).subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.formularioFinalizado = true;
        this.sesion.actualizarPropiedadUser('fecha_encuesta_nps', this.date.format(new Date()));
      },
      error: (err: any) => {
        console.error(err);
        this.sentry.captureException(err);
        this.loading.hide();
        this.alerts.addAlert("Ha ocurrido un error.", "danger");
        if (err.error.msg) {
          this.alerts.addAlert(err.error.msg, "danger");
        }
        if (err.error.errors && err.error.errors[0]) {
          this.alerts.addAlert(err.error.errors[0].msg, "danger");
        }
      }
    });
  }
}
