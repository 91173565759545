import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/utils/config/config.service';
import { SesionService } from 'src/app/utils/sesion/sesion.service';

@Injectable({
    providedIn: 'root'
})
export class SuscripcionesService {

    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private sesion: SesionService
    ) { }

    ventipayController: string = 'venti-pay/';
    dLocalGoController: string = 'dlocalgo/';

    create(element: any): Observable<any> {
        return this.http.post(this.config.apiUrl + this.ventipayController + `create`, element, this.sesion.headers);
    }

    view(id: number): Observable<any> {
        return this.http.get(this.config.apiUrl + this.ventipayController + `view/${id}`, this.sesion.headers);
    }

    select(): Observable<any> {
        return this.http.get(this.config.apiUrl + this.ventipayController + `select`, this.sesion.headers);
    }

    update(element: any): Observable<any> {
        return this.http.put(this.config.apiUrl + this.ventipayController + `update/${element.id}`, element, this.sesion.headers);
    }

    delete(id: number): Observable<any> {
        return this.http.delete(this.config.apiUrl + this.ventipayController + `delete/${id}`, this.sesion.headers);
    }

    list(): Observable<any> {
        return this.http.get(this.config.apiUrl + this.ventipayController + `list`, this.sesion.headers);
    }

    activate(id: number): Observable<any> {
        return this.http.put(this.config.apiUrl + this.ventipayController + `activate/${id}`, {}, this.sesion.headers);
    }

    deactivate(id: number): Observable<any> {
        return this.http.put(this.config.apiUrl + this.ventipayController + `deactivate/${id}`, {}, this.sesion.headers);
    }

    getEnlacePago(id: number, data: any) {
        return this.http.post(this.config.apiUrl + (this.sesion.esPaisChile(this.sesion.usuario.pais_id) ? this.ventipayController : this.dLocalGoController) + `solicitar-enlace-pago/${id}`, data, this.sesion.headers);
    }

    getEnlacePagoEnlaceExterno(id: number, paisID: number, data: any) {
        return this.http.post(this.config.apiUrl + (this.sesion.esPaisChile(paisID) ? this.ventipayController : this.dLocalGoController) + `solicitar-enlace-pago/${id}`, data, this.sesion.headers);
    }

    enviarEnlacePagoEnlaceExternoEnEmail(data: any) {
        return this.http.post(this.config.apiUrl + this.ventipayController + `envio-email-suscripcion`, data, this.sesion.headers);
    }

    misSuscripciones(): Observable<any> {
        return this.http.get(this.config.apiUrl + (this.sesion.esPaisChile(this.sesion.usuario.pais_id) ? this.ventipayController : this.dLocalGoController) + `subscriptions`, this.sesion.headers);
    }

    misPagos(): Observable<any> {
        return this.http.get(this.config.apiUrl + (this.sesion.esPaisChile(this.sesion.usuario.pais_id) ? this.ventipayController : this.dLocalGoController) + `payments`, this.sesion.headers);
    }

    planActual(): Observable<any> {
        return this.http.get(this.config.apiUrl + (this.sesion.esPaisChile(this.sesion.usuario.pais_id) ? this.ventipayController : this.dLocalGoController) + `plan-actual`, this.sesion.headers);
    }
}
